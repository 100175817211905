






































import _ from 'lodash';
import {Component, Mixins, Prop, Watch, Vue} from 'vue-property-decorator';
import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import {LookupService} from '@/services/LookupService';

@Component({
  name: 'FactCompanyAutoComplete',
  components: {
    FactTooltip
  }
})
export default class FactCompanyAutoComplete extends Mixins(FactMixin) {

  private suggestions = [];
  private search = '';
  private loading = false;

  private xx = _.debounce(async (that, name) => {
    if (!name) {
      return;
    }
    that.loading = true;
    that.suggestions = await LookupService.searchByName(name) || [];
    that.loading = false;
  }, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);

  @Watch('search')
  private getResult(name) {
    this.xx(this,  name);
  }

  private onSearch(text: string) {
    this.modelValue = text;
    this.updateModelAndValidate(this.modelValue);
    this.search = text;
  }

  private onItemClick(item: any) {
    this.$emit('selectedCompany', _.get(item, 'Abn', ''));
  }
}

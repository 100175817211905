






































import {Component, Mixins, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
  name: 'FactUpload',
  components: {
    FactTooltip
  }
})
export default class FactUpload extends Mixins(FactMixin) {
  @Prop() private buttonText!: string;
  private isSelecting: boolean = false;
  private selectedFile: any;
  private fileName: any = '';
  get label() {
    return (!this.separateHeader && this.description) || '';
  }

  private removeSelectedFile() {
    this.fileName = null;
    this.updateModelAndValidate({selectedFile: this.selectedFile, remove: true , id: this.factData.id}, true);
  }

  private onFileChanged(e) {
    this.selectedFile = e.target.files[0];
    this.fileName = this.selectedFile.name;

    const reader = new FileReader();

    reader.onload = () => {
      console.log(reader.result);
    };

    reader.readAsArrayBuffer(e.target.files[0]);
    this.updateModelAndValidate({selectedFile: this.selectedFile, id: this.factData.id}, true);
  }

  private onChange(e: Event) {
    const value = _.get(e, 'target.value', '');
    this.updateModelAndValidate(value.trim());
  }

  private handleFileImport() {
    (this.$refs.uploader as any)?.click();
  }

  private autoFill(e: Event) {
    this.updateModelAndValidate(this.modelValue.trim(), true, true);
  }

  private mounted() {
    this.onChange = _.debounce(this.onChange, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
  }
}

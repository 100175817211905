



























import {Component, Mixins, Prop} from 'vue-property-decorator';
import _ from 'lodash';
import {Action} from 'vuex-class';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import FactMultiSelect from './FactMultiSelect.vue';
import { IMultiFactUpdatePayload } from '@/interfaces';

@Component({
  name: 'CustomMultiBoolean',
  components: {
    FactTooltip,
    FactMultiSelect
  }
})
export default class CustomMultiBoolean extends Mixins(FactMixin) {
  @Action('quotation/updateMultiFact') private updateMultiFact!: (payload: IMultiFactUpdatePayload) => void;

  private update(updates) {
    this.updateMultiFact({productID: this.factData._productID, productCode: this.factData._productCode, facts: updates});
  }
}















import {Component, Prop, Vue} from 'vue-property-decorator';
import {FactService} from '@/services/FactService';
import { Getter } from 'vuex-class';

@Component({
  name: 'Illustration',
})
export default class Illustration extends Vue {
  @Prop({required: true}) private title!: string;
  @Prop() private img!: string;
  @Prop({
    default: '180px'
  }) private height!: string;
  @Prop({
    default: '195px'
  }) private width!: string;

  get isShow() {
    if (this.$vuetify.breakpoint.xsOnly) {
      return false;
    } else {
      return true;
    }
  }
}


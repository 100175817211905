





























































import {Component, Vue, Prop} from 'vue-property-decorator';
import {State} from 'vuex-class';
import _ from 'lodash';
import Util from '@/utils/Util';

@Component({
  name: 'ResponsiveTable'
})

export default class ResponsiveTable extends Vue {
  @Prop() private options!: any;

  private handleItems(items) {
    return Array.isArray(items) ? items : [items];
  }

  private getRowColor(index) {
    if (index === 0) {
      return this.$colors.primary;
    } else if (index % 2 === 1) {
      return 'white';
    } else {
      return '#F9F9FA';
    }
  }

  private getTitle(obj) {
    return Object.keys(obj)[0];
  }

  private getItems(obj) {
    return _.isArray(obj) || _.isString(obj) ? obj : Object.values(obj)[0];
  }

  get isMobile() {
    return _.get(this, Util.MOBILE_BREAKPOINT);
  }

}

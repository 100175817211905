import { render, staticRenderFns } from "./FactInfo.vue?vue&type=template&id=7820ccfa&scoped=true&"
import script from "./FactInfo.vue?vue&type=script&lang=ts&"
export * from "./FactInfo.vue?vue&type=script&lang=ts&"
import style0 from "./FactInfo.vue?vue&type=style&index=0&id=7820ccfa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7820ccfa",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VLayout})






import {Component, Vue, Prop} from 'vue-property-decorator';
import Util from '@/utils/Util';
import VueMarkdown from 'vue-markdown';

@Component({
	name: 'MarkdownText',
  components: {
    VueMarkdown
  }
})
export default class MarkdownText extends Vue {
  @Prop() private content!: string;

  private formatted = '';

  private created() {
    this.format();
  }

  private async format() {
    this.formatted = await Util.markdownFormatted(this.content);
  }
}






















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {FactService} from '@/services/FactService';
import { Getter } from 'vuex-class';

@Component({
  name: 'Section',
  components: {
    FactList: () => import('@/components/common/form/fact/FactList.vue'),
    FactTooltip: () => import('@/components/common/form/fact/FactTooltip.vue'),
    FactSelection: () => import('@/components/common/form/fact/FactSelection.vue'),
    FactOption: () => import('@/components/common/form/fact/FactOption.vue'),
    FactDate: () => import('@/components/common/form/fact/FactDate.vue'),
    FactText: () => import('@/components/common/form/fact/FactText.vue'),
    FactAgree: () => import('@/components/common/form/fact/FactAgree.vue'),
    FactMultilineText: () => import('@/components/common/form/fact/FactMultilineText.vue'),
    FactNumber: () => import('@/components/common/form/fact/FactNumber.vue'),
    FactAddressAutoComplete: () => import('@/components/common/form/fact/FactAddressAutoComplete.vue'),
    FactPhoneNumber: () => import('@/components/common/form/fact/FactPhoneNumber.vue'),
    FactCompanyAutoComplete: () => import('@/components/common/form/fact/FactCompanyAutoComplete.vue')
  },
})

export default class Section extends Vue {
  @Prop({required: true}) private title!: string;
  @Prop() private name!: string;
  @Prop() private updateFn!: any;
  @Getter('quotation/hasSectionFacts') private hasSectionFacts!: (sectionName: string) => boolean;
  @Getter('quotation/getSectionFacts') private getSectionFactsGetter!: (sectionName: string) => any;

  private hasContent: boolean = false;
  private facts: any[] = [];

  private checkHasContent() {
    return this.hasSectionFacts(this.name);
  }

  private getSectionFacts() {
    return this.getSectionFactsGetter(this.name);
  }

  private getConfigByType(fact: any) {
    return FactService.getConfigByType(fact);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private mounted() {
    this.hasContent = this.checkHasContent();
    this.facts = this.getSectionFacts();
  }

  private activated() {
    this.hasContent = this.checkHasContent();
    this.facts = this.getSectionFacts();
  }
}


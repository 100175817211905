











import {Component, Mixins, Prop} from 'vue-property-decorator';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
  name: 'FactInfo',
  components: {
    FactTooltip
  }
})
export default class FactInfo extends Mixins(FactMixin) {
}

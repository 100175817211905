import { render, staticRenderFns } from "./FactSelection.vue?vue&type=template&id=5135fe79&scoped=true&"
import script from "./FactSelection.vue?vue&type=script&lang=ts&"
export * from "./FactSelection.vue?vue&type=script&lang=ts&"
import style0 from "./FactSelection.vue?vue&type=style&index=0&id=5135fe79&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5135fe79",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VCheckbox,VFlex,VLayout,VSwitch})








































































import {Component, Mixins} from 'vue-property-decorator';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import _ from 'lodash';
import Util from '@/utils/Util';

const AGREEMENT_FACTS = 'AGREEMENT_FACTS';

@Component({
  name: 'FactAgree',
  components: {
    FactTooltip
  }
})
export default class FactAgree extends Mixins(FactMixin) {
  private val = '';
  private showDialog = false;

  private toggle(value = this.val === 'true' ? 'false' : 'true') {
    this.val = value;
    setTimeout(() => {
      this.modelValue = this.val;
      this.updateModelAndValidate(this.modelValue);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS);
  }

  private mounted() {
    this.val = this.modelValue;
  }

  private action() {
    this.showDialog = false;
    setTimeout(() => {
      this.toggle();
    }, 100);
  }

  get name() {
    return _.get(_.get(this.app.config.shiftMappings, AGREEMENT_FACTS, {}), _.get(this, 'factData.id').split('#').pop());
  }
}

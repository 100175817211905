










import {Component, Vue} from 'vue-property-decorator';

@Component({ name: 'PaymentOptions' })
export default class PaymentOptions extends Vue {
}



























































import {Component, Mixins, Watch} from 'vue-property-decorator';
import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import moment from 'moment';
import EventBus from '@/common/EventBus';
import _ from 'lodash';

const SHIFT_DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  name: 'FactDate',
  components: {
    FactTooltip
  }
})
export default class FactDate extends Mixins(FactMixin) {
  private menu: boolean = false;
  private formattedDate: string = '';

  get datePickerValue() {
    if (this.formattedDate && moment(this.formattedDate, this.getDateFormat()).isValid()) {
      return moment(this.formattedDate, this.getDateFormat()).format(SHIFT_DATE_FORMAT);
    } else {
      return moment().format(SHIFT_DATE_FORMAT);
    }
  }

  private mounted() {
    if (this.modelValue) {
      const val = this.modelValue;
      this.modelValue = '';
      this.onUpdate(val);
    }
    this.onTextInput = _.debounce(this.onTextInput, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
  }

  @Watch('modelValue')
  private onUpdate(val) {
    if (val || this.modelValue) {
      this.$nextTick(() => {
        this.onDateInput(val || this.modelValue);
      });
    }
  }

  private onTextInput(val) {
    if (val && val.length === this.getDateFormat().length) {
      if (moment(val, this.getDateFormat()).isValid()) {
        this.formattedDate = val;
        this.modelValue = moment(val, this.getDateFormat()).format(SHIFT_DATE_FORMAT);
      } else {
        this.formattedDate = '';
        this.modelValue = '';
      }
      this.updateModelAndValidate(this.modelValue);
    }
  }

  private onDateInput(val) {
    this.menu = false;
    this.modelValue = val;
    this.formattedDate = moment(val, SHIFT_DATE_FORMAT).format(this.getDateFormat());
    this.updateModelAndValidate(this.modelValue);
  }
}

















































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import _ from 'lodash';
import {State} from 'vuex-class';
import { formatCurrency } from '@/filters';

const IsNumeric = (input: string) => {
  const RE = /^-{0,1}\d*\.{0,1}\d+$/;
  return (RE.test(input));
};

const formatBenefitAmountType = (val: any) => {
  const currency: string = val.substr(0, val.indexOf(':'));
  const amount = val.substr(val.indexOf(':') + 1, val.length);
  if (typeof val === 'string' && IsNumeric(amount)) {
    const intValue = amount.indexOf('.') === -1 ? parseInt(amount, 10) : parseFloat(amount);
    return formatCurrency(intValue, currency);
  }
  return val;
};

@Component({
  name: 'Benefits'
})
export default class Benefits extends Vue {
  @State private app: any;
  @State private cms: any;
  @Prop() private benefitsArray!: any[];
  @Prop({ default: () => [] }) private assetsArray!: any[];
  @Prop() private expandable!: boolean;
  @Prop() private expanded!: any;
  @Prop() private data!: any;
  @Prop() private price!: any;
  @Prop() private index!: number;
  @Prop() private productCode!: string;
  @Prop() private productTitle!: string;
  @Prop() private reviewRequired!: boolean;

  private dialog = false;

  get filteredBenefits() {
    return _.filter([...this.benefitsArray, ...this.assetsArray], (b) => {
      return !b.hidden;
    });
  }

  get simple() {
    return (this.$attrs as any).simple === '' || !!(this.$attrs as any).simple;
  }

  get topLevelBenefits() {
    return _.filter(this.filteredBenefits, (b) => !b.childOf);
  }

  private getSubBenefits(benefit: any) {
    let found: any[] = [];
    _.forEach(this.filteredBenefits, (b) => {
      if (b.id !== benefit.id && b.childOf === benefit.id) {
        found = [...found, b, ...this.getSubBenefits(b)];
      }
    });
    return found;
  }

  private getNumberOfParents(benefit: any, level: number) {
    if (benefit.childOf) {
      const parent = this.filteredBenefits.find((b) => b.id === benefit.childOf);
      return this.getNumberOfParents(parent, level + 1);
    }
    return level;
  }

  private getSubBenefitClass(subbenefit, isValue = false, simple = false) {
    const classes = {benefit: true, 'this.excluded-benefit': subbenefit.excluded, 'text-xs-right': isValue, 'pr-5 pl-2 value': simple};
    classes[this.getLevel(subbenefit)] = true;
    return classes;
  }

  private getLevel(benefit) {
    const nested = this.getNumberOfParents(benefit, 1);
    return 'level-' + nested;
  }

  get showBenefits() {
    const customRules = _.get(this.app, 'config.shiftMappings.BENEFIT_RULES', []); // Example: "BENEFIT_RULES": [{"product": "CMC-E&O", "code": "cgl", "rule": "SELECTED"}]
    const defaultRules = [{code: 'base', rule: 'ALWAYS'}, {rule: 'PRICE'}];
    const rules = customRules.concat(defaultRules);
    if (rules.length) {
      for (const r of rules) {
        if (r.product !== undefined && r.product !== this.productCode) {
          continue;
        }
        if (r.code !== undefined && r.code !== this.data.code) {
          continue;
        }
        switch (r.rule) {
          case 'ALWAYS': return true;
          case 'HIDDEN': return false;
          case 'PRICE': return +this.price > 0 || this.simple;
          case 'SELECTED': {
            for (const b of this.topLevelBenefits) {
              if (b.value !== 'Not selected') {
                return true;
              }
              const subbenefits: any = this.getSubBenefits(b);
              for (const sb of subbenefits) {
                if (sb.value !== 'Not selected') {
                  return true;
                }
              }
            }
            return false;
          }
        }
      }
    }
  }

  private getBenefitValue(benefit) {
    const val: string = _.get(benefit, 'value', benefit.defaultValue);
    return _.get(benefit, 'type') === 'AMOUNT' ? formatBenefitAmountType(val) : val;
  }

  private getBenefitName(benefit) {
    return `${benefit.name}${!this.getBenefitValue(benefit) ? '' : ' – '}`;
  }

  private toggle() {
    this.expanded = this.expanded ? '' : '0';
  }
}

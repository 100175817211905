











































import {Component, Mixins, Prop} from 'vue-property-decorator';
import _ from 'lodash';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
  name: 'FactText',
  components: {
    FactTooltip
  }
})
export default class FactText extends Mixins(FactMixin) {
  get label() {
    return (!this.separateHeader && this.description) || '';
  }

  get formattedValue() {
    if (this.app.config.shiftMappings.CAPITALIZE_FACT && this.app.config.shiftMappings.CAPITALIZE_FACT.includes(this.factData.id)) {
      return this.modelValue.toUpperCase();
    }
    return this.modelValue;
  }

  set formattedValue(value: string) {
    if (this.app.config.shiftMappings.CAPITALIZE_FACT && this.app.config.shiftMappings.CAPITALIZE_FACT.includes(this.factData.id)) {
      this.modelValue = value.toUpperCase();
    } else {
      this.modelValue = value;
    }
  }

  private onChange(e: Event) {
    const value = _.get(e, 'target.value', '');
    this.updateModelAndValidate(value.trim());
  }

  private autoFill(e: Event) {
    this.updateModelAndValidate(this.modelValue.trim(), true, true);
  }

  private mounted() {
    this.onChange = _.debounce(this.onChange, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
  }
}

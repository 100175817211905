import { render, staticRenderFns } from "./FactUpload.vue?vue&type=template&id=6d46c8d3&scoped=true&"
import script from "./FactUpload.vue?vue&type=script&lang=ts&"
export * from "./FactUpload.vue?vue&type=script&lang=ts&"
import style0 from "./FactUpload.vue?vue&type=style&index=0&id=6d46c8d3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d46c8d3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout})















































import {Component, Mixins, Watch} from 'vue-property-decorator';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import _ from 'lodash';

@Component({
  name: 'FactOption',
  components: {
    FactTooltip
  }
})
export default class FactOption extends Mixins(FactMixin) {
  private val = '';

  @Watch('modelValue')
  private externalUpdate() {
    if (this.modelValue !== this.val) {
      this.toggle(this.modelValue);
    }
  }

  private toggle(value) {
    this.val = this.val === value.toString() ? '' : value.toString();
    this.update();
  }

  private update(forced: boolean = false) {
    this.modelValue = this.val;
    this.updateModelAndValidate(this.modelValue, forced);
  }

  private mounted() {
    this.val = this.modelValue;
    this.update = _.debounce(this.update, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
    this.update(true);
  }
}








































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import _ from 'lodash';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
	name: 'FactAddressAutoComplete',
  components: {
    FactTooltip
  }
})
export default class FactAddressAutoComplete extends Mixins(FactMixin) {
  @Prop() private assetIndex!: string;

  private autocompleteService: any = null;
  private placeService: any = null;
  private sessionToken: any = null;
  private dummyDiv: HTMLDivElement | null = null;
  private suggestions: any[] = [];
  private search: string = '';
  private loading: boolean = false;
  private resolved: string = '';
  private getSuggestions: any = null;

  get gmapServiceReady() {
    return !!this.autocompleteService && !!this.sessionToken;
  }

  private mounted() {
    this.getSuggestions = _.debounce(this.getSuggestionsFunc, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
    this.dummyDiv = document.createElement('div');

    this.setupGMapService();

    const input = document.querySelector('input[name="' + this.getUID() + '"]') as HTMLInputElement;
    if (input) {
      input.onkeydown = (e) => {
        if (e.key === 'Tab') {
          this.$emit('keydown-tab', e);
        }
      };
    }
  }

  private setupGMapService() {
    if ((window as any).google) {
      this.autocompleteService = new (window as any).google.maps.places.AutocompleteService();
      this.placeService = new (window as any).google.maps.places.PlacesService(this.dummyDiv);
      this.sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    }
  }

	private async onValueChange(this: any, event: any) {
    const value = this.modelValue;
    this.resolved = this.modelValue;
    const tempAddress = _.get(_.get(value, 'description', '').split(', '), '[0]', false);
    if (tempAddress) {
      this.$nextTick(() => {
        this.modelValue = tempAddress;
        this.updateModelAndValidate(this.modelValue);
      });
    }
    if (!_.isEmpty(value) && !_.isEmpty(value.place_id)) {
      const callback = (result: any, status: any) => {
        if (status === 'OK') {
          const streetNumber = result.address_components.find((c) => c.types.includes('street_number'));
          const street = result.address_components.find((c) => c.types.includes('route'));
          const postalCode = result.address_components.find((c) => c.types.includes('postal_code'));
          const city = result.address_components.find((c) => c.types.includes('locality'));
          const state = result.address_components.find((c) => c.types.includes('administrative_area_level_1'));
          const country = result.address_components.find((c) => c.types.includes('country'));
          const suburb = result.address_components.find((c) => c.types.includes('sublocality_level_1'));
          this.$emit('selectedAddress', this.factData._productID, this.factData.id, {streetNumber, street, postalCode, city, state, country, suburb});
        }
        this.loading = false;
      };
      const request = {
        placeId: value.place_id,
        fields: ['address_components'],
        sessionToken: this.sessionToken
      };
      this.loading = true;
      this.placeService.getDetails(request, callback);
      this.sessionToken = new (window as any).google.maps.places.AutocompleteSessionToken();
    }
	}

	private onInput(value) {
    this.modelValue = value || '';
    this.updateModelAndValidate(this.modelValue);
  }

  @Watch('search')
  private onSearch(newValue, oldValue) {
    if (newValue !== oldValue) {
      this.getSuggestions();
    }
  }

  private async getSuggestionsFunc() {
    // Test autocomplete
    // this.$emit('selectedAddress', this.factData._productID, this.factData.id, {streetNumber: {
    //   short_name: 'test',
    //   long_name: 'test'
    // }, street: {
    //   short_name: 'test',
    //   long_name: 'test'
    // }, postalCode: {
    //   short_name: '3000',
    //   long_name: '3000'
    // }, city: {
    //   short_name: 'test',
    //   long_name: 'test'
    // }, state: {
    //   short_name: 'VIC',
    //   long_name: 'VIC'
    // }, country: {
    //   short_name: 'VIC',
    //   long_name: 'VIC'
    // }, suburb: {
    //   short_name: 'VIC',
    //   long_name: 'VIC'
    // }});
    if (this.resolved || (this.search === this.modelValue)) {
      if (this.resolved !== this.search) {
        this.resolved = '';
      }
      return;
    }
    const search = this.search;
    this.modelValue = _.isEmpty(this.search) ? '' : search;
    this.updateModelAndValidate(this.modelValue);
    if (_.isEmpty(this.search)) {
      return;
    }
    this.suggestions = [];
    this.loading = true;
    const callback = (predictions: any, status: any) => {
      this.loading = false;
      if (status === 'OK') {
        this.suggestions = _.filter(predictions, (prediction) => !!prediction);
      }
    };

    if (!this.gmapServiceReady) {
      this.setupGMapService();
      if (!this.gmapServiceReady) {
        return;
      }
    }
    const cfg: any = {
      input: search,
      types: ['address'],
      sessionToken: this.sessionToken
    };
    if (_.get(this.app, 'config.addressComplete.componentRestrictions')) {
      cfg.componentRestrictions = this.app.config.addressComplete.componentRestrictions;
    }
    this.autocompleteService.getPlacePredictions(cfg, callback);
  }
}
























































import {Component, Mixins} from 'vue-property-decorator';
import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import {State} from 'vuex-class';
import _ from 'lodash';

@Component({
  name: 'FactNumber',
  components: {
    FactTooltip
  }
})
export default class FactNumber extends Mixins(FactMixin) {
  private focused: boolean = false;

  private isSafeInteger(value) {
    return Number(value) <= Number.MAX_SAFE_INTEGER;
  }

  get formatTypes() {
    function set(obj, value) {
      clearTimeout(obj.resetTimer);
      if (value === '') {
        obj.modelValue = '';
        obj.resetTimer = setTimeout(() => {
          obj.modelValue = _.get(obj, 'preloadedValue', '');
        }, 1000);
      } else {
        obj.modelValue = Number(value).toString();
      }
    }
    return {
      integer: {
        value: 'integer',
        handler: (event) => {
          this.checkInteger(event);
        },
        setValue: (value) => {
          // prevent invalid number like NaN , Infinity and etc
          if (/^\d+$/.test(value)) {
            set(this, value);
          } else {
            set(this, 0);
          }
        },
        formatter: (value) => value
      },
      float: {
        value: 'float',
        handler: (event) => {
          const floatRegex = /^\d+\.?\d*$/;
          if (!this.isSafeInteger(this.modelValue) || !floatRegex.test(this.modelValue + event.key)) {
            event.preventDefault();
          }
        },
        setValue: (value) => {
          set(this, value);
        },
        formatter: (value) => value
      },
      currency: {
        value: 'currency',
        handler: (event) => {
          const floatRegex = /^\d+\.?\d*$/;
          if (!this.isSafeInteger(this.modelValue) || !floatRegex.test(this.modelValue + event.key)) {
            event.preventDefault();
          }
        },
        setValue: (value) => {
          const regex  = /^\d+\.?\d{0,2}$/;
          if (!value) {
            set(this, 0);
          }
          if (value && regex.test(value)) {
            set(this, value);
          }
        },
        formatter: (value, focused) => focused ? this.modelValue : this.$options.filters!.currency(Number(value), '')
      },
      percent: {
        value: 'percent',
        handler: (event) => {
          this.checkInteger(event);
        },
        setValue: (value) => {
          this.modelValue = Number(value).toString();
        },
        formatter: (value, focused) => this.$options.filters!.percentage(value, '%', focused)
      }
    };
  }

  get type() {
    if (this.formatTypes[this.factData.formatType]) {
      return this.factData.formatType;
    } else {
      return this.formatTypes.integer.value;
    }
  }

  get formattedModelValue() {
    return this.formatTypes[this.type].formatter(this.modelValue, this.focused);
  }

  private checkInteger(event) {
    let data;
    if (event.type === 'paste') {
      event.stopPropagation();
      const clipboardData = event.clipboardData || (window as any).clipboardData;
      data = clipboardData.getData('Text');
    } else {
      data = event.key;
    }
    if (!this.isSafeInteger(this.modelValue) || !Number.isInteger(parseInt(data, 10)) || parseInt(data, 10) !== parseFloat(data)) {
      event.preventDefault();
    }
  }

  private onFocus(event) {
    this.focused = true;
    if (_.get(event, 'target', false)) {
      this.$nextTick( () => {
        event.target.select();
      });
    }
    this.onFocusHandler();
  }

  private onChange() {
    this.updateModelAndValidate(this.modelValue);
  }

  private onBlur() {
    this.updateModelAndValidate(this.modelValue);
    this.focused = false;
    this.onBlurHandler();
  }

  private mounted() {
    this.onChange = _.debounce(this.onChange, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
  }
}

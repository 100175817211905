






















































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';

import Util from '@/utils/Util';
import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
  name: 'FactSelection',
  components: {
    FactTooltip
  }
})

export default class FactSelection extends Mixins(FactMixin) {
  @Prop() private type;
  private val: any = false;

  private mounted() {
    if (this.app.isRenewal || this.app.isSavedProposal) {
      this.val = '';
    } else {
      this.val = this.modelValue;
    }
  }

  @Watch('val')
  private toggle() {
    if (!this.val) {
      this.val = '';
    }
    setTimeout(() => {
      this.modelValue = this.val;
      this.updateModelAndValidate(this.modelValue);
    }, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS);
  }
}

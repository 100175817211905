







































import {Component, Mixins, Prop} from 'vue-property-decorator';
import _ from 'lodash';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
  name: 'FactMultilineText',
  components: {
    FactTooltip
  }
})
export default class FactMultilineText extends Mixins(FactMixin) {
  @Prop() private hideDetails;

  private onChange(e: Event) {
    const value = _.get(e, 'target.value', '');
    this.modelValue = value;
    this.updateModelAndValidate(this.modelValue);
  }

  private mounted() {
    this.onChange = _.debounce(this.onChange, this.FACT_CHANGE_DEBOUNCE_TIME_OUT);
  }
}

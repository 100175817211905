













































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import _ from 'lodash';
import config from '@/config';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';

@Component({
	name: 'FactList',
  components: {
    FactTooltip
  }
})
export default class FactList extends Mixins(FactMixin) {
  @Prop() private hasTitle!: boolean;

  @Watch('factData.options')
  private preselectOption(options) {
    if (options.length === 1) {
      this.modelValue = options[0].value;
      this.updateModelAndValidate(this.modelValue);
    }
  }

  get value() {
    const option = _.find(this.factData.options, (o) => o.name === this.modelValue || o.value === this.modelValue);
    return option ? option.value : null;
  }

  set value(value: any) {
    this.modelValue = value;
    this.updateModelAndValidate(this.modelValue);
  }

  get isDropdown() {
    if (this.factData.options.length > parseInt(_.get(config, 'layout.listLimit', '0'), 10)) {
      return true;
    }
    const dropdown = _.get(this.app, 'config.shiftMappings.RENDER_AS_DROPDOWN', []);
    const patchedId = this.factData.id.replace(/:\d+/, ':x');
    return !!_.find(dropdown, (id) => id.includes(patchedId));
  }

  private adjustChips(id) {
    setTimeout(() => {
      try {
        const container = document.querySelector(`[id='${id}']`) as HTMLElement;
        if (!container) {
          return;
        }
        const containerWidth = window.getComputedStyle(container).getPropertyValue('width');
        _.forEach([...document.querySelectorAll(`button[id='${id}']`)], (chip) => {
          chip.classList.remove('full-chip');
          chip.classList.remove('half-chip');
          const isSmaller = parseInt(containerWidth, 10) / 2 > chip.clientWidth;
          if (isSmaller && !this.$vuetify.breakpoint.xsOnly) {
            chip.classList.add('half-chip');
          } else {
            chip.classList.add('full-chip');
          }
        });
      } catch (e) {
        return;
      }
    }, 0);

  }

  private isActive(option) {
    return this.value === option.value;
  }

  private onOptionClick(option) {
    this.modelValue = this.modelValue === option.value ? '' : option.value;
    this.updateModelAndValidate(this.modelValue);
  }

  private mounted() {
    const input = document.querySelector('input[name="' + this.getUID() + '"]') as HTMLInputElement;
    if (input) {
      input.onkeydown = (e) => {
        if (e.key === 'Tab') {
          this.$emit('keydown-tab', e);
        }
      };
    }
  }

  private onFocus() {
    const input = document.querySelector('input[name="' + this.getUID() + '"]') as HTMLInputElement;
    input.click();
  }
}

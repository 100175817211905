










































































import {FactService} from '@/services/FactService';
import {Component, Vue, Prop} from 'vue-property-decorator';

import _ from 'lodash';
import {State} from 'vuex-class';
import config from '@/config';
import Util from '@/utils/Util';

import FactText from '@/components/common/form/fact/FactText.vue';
import FactDate from '@/components/common/form/fact/FactDate.vue';
import FactOption from '@/components/common/form/fact/FactOption.vue';
import FactMultilineText from '@/components/common/form/fact/FactMultilineText.vue';
import FactNumber from '@/components/common/form/fact/FactNumber.vue';
import FactSelection from '@/components/common/form/fact/FactSelection.vue';
import FactList from '@/components/common/form/fact/FactList.vue';
import FactLabel from '@/components/common/form/fact/FactLabel.vue';
import FactInfo from '@/components/common/form/fact/FactInfo.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import FactMultiSelect from '@/components/common/form/fact/FactMultiSelect.vue';

@Component({
  name: 'AssetSection',
  inject: ['$validator'],
  components: {
    FactList,
    FactLabel,
    FactTooltip,
    FactSelection,
    FactOption,
    FactDate,
    FactText,
    FactMultilineText,
    FactMultiSelect,
    FactNumber,
    FactInfo
  },
})
export default class AssetSection extends Vue {
  @Prop() private assetGroupsFacts!: any;
  @Prop() private assetGroupColumns!: any;
  @Prop() private assetIndex!: number;
  @Prop() private insuredType!: any;
  @Prop() private canRemoveAsset!: boolean;
  @Prop() private removeAsset!: any;
  @Prop() private showGroup!: any;
  @Prop() private factGroupText!: any;
  @Prop() private groupSingleFact!: any;
  @Prop() private singleFactDescription!: any;
  @Prop() private product!: any;
  @Prop() private data!: any;
  @Prop() private asset!: any;
  @Prop() private updateFn!: any;
  @Prop() private registerClass!: any;
  @State private app: any;

  private isNumericText(fact) {
    return FactService.isNumericText(fact);
  }

  private getValidators(fact: any) {
    return FactService.getValidators(fact);
  }

  private hasAutoFill(fact: any) {
    return Util.hasAutoFill(fact);
  }

  private getConfigByType(componentType: string, options: any = [], id: string = '') {
    if (componentType === 'GROUP' && !id.endsWith(_.get(this.app.config.shiftMappings, 'ASSET_HEADER', {})[this.insuredType.type])) {
      return;
    }
    if (componentType === 'BOOLEAN') {
      return 'FactOption';
    }
    if (componentType === 'LIST' && (options && options.length) >= parseInt(config.layout.multiselectLimit, 10)) {
      return 'FactMultiSelect';
    }
    return FactService.getFactType(componentType);
  }
}

import { render, staticRenderFns } from "./FactMultilineText.vue?vue&type=template&id=1baf48c2&scoped=true&"
import script from "./FactMultilineText.vue?vue&type=script&lang=ts&"
export * from "./FactMultilineText.vue?vue&type=script&lang=ts&"
import style0 from "./FactMultilineText.vue?vue&type=style&index=0&id=1baf48c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1baf48c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VFlex,VLayout,VTextarea})











































































































































































import {FactService} from '@/services/FactService';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {State, Getter} from 'vuex-class';
import utils, { FactTag } from '@/views/auth/Portal/PortalStepperItems/Utils';
import ProductEngineUtils from '@/utils/ProductEngineUtils';
import Benefits from '@/components/products/Benefits.vue';
import FactTooltip from '@/components/common/form/fact/FactTooltip.vue';
import {Product} from '@/models';
import _ from 'lodash';
import { IQuotedPrice } from '@/interfaces';

@Component({
  components: {
    Benefits,
    FactTooltip
  }
})
export default class Breakdown extends Vue {
  @State private app: any;
  @Getter('proposal/getSelectedProducts') private getSelectedProducts: any;
  @Getter('quotation/isPriceValidByProductId') private isPriceValidByProductId!: (productID: string, isContinuation: boolean) => boolean;

  @Prop() private title!: string;
  @Prop() private id!: string;
  @Prop() private product!: any;
  @Prop() private price!: string;
  @Prop() private buckets!: any[];
  @Prop() private coverageDates!: string;
  @Prop() private afterUW!: boolean;
  @Prop() private review?: boolean;
  @Prop() private quotedPrice !: IQuotedPrice;

  private getPayablePrice() {
    if (this.quotedPrice.price.buckets && this.quotedPrice.price.buckets.length) {
      let total = 0;
      _.forEach(this.buckets, (bucket) => {
        total += bucket.totalPrice;
      });
      return total;
    } else {
      return this.price;
    }
  }

  private isBPL() {
    return this.quotedPrice.productDetail.code === 'DHLTH-BPL';
  }

  private getComponentPrice(productID: string, componentIndex: number) {
    if (this.isPriceValidByProductId(productID, this.app.isContinuation)) {
      return this.quotedPrice.price.componentResult[componentIndex].buckets[0].totalPrice -
        this.quotedPrice.price.componentResult[componentIndex].buckets[0].refund;
    }
    return 0;
  }

  private hasBenefits(component) {
    const sectionConfig = _.get(this.app.config.shiftMappings, `SECTION_GROUPS_CONFIG[${component.name}]`) || {};
    return _.get(component, 'benefits.length') ||
            ((component.code === sectionConfig.component || component.code === 'secondary') && _.get(component, 'assets[0].benefits.length'));
  }

  private getAssets(assets) {
    let ret: any[] = [];
    _.forEach(assets, (asset, index) => {
      if (!_.isEmpty(asset)) {
        let retAsset: any[] = [];
        if (!_.isEmpty(asset.benefits)) {
          const fact = _.find(asset.allFacts, (a) => _.includes(a.id, '.name'));
          retAsset.push({
            value: fact && fact.currentValue ? fact.currentValue : '',
            name: `${asset.type} ${index + 1} ${this.getInsuredInfoFact(asset.allFacts)}`,
            id: 'asset.name',
            asset: true
          });
        }
        retAsset = [...retAsset, ...asset.benefits];
        ret = [...ret, ...retAsset];
      }
    });
    return ret;
  }

  private getInsuredInfoFact(allFacts: any[]): string {
    const insuredInfo = allFacts.filter((fact: any) => fact.tags.includes(FactTag.INSURED_INFO) ).map((fact: any) => fact.currentValue).join(', ') || '';
    return insuredInfo ? `( ${insuredInfo} )` : '';
  }

  private getProratedDiscount(id: any) {
    return (this.quotedPrice.price.buckets && - (this.quotedPrice.price.buckets[0]?.totalPriceBeforeProration - this.quotedPrice.price.buckets[0]?.totalPrice)) || 0;
  }
}

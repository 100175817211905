













































































































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import _ from 'lodash';
import Util from '@/utils/Util';

import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import {Getter} from 'vuex-class';
import { IFactObject } from '@/interfaces';

@Component({
  name: 'FactMultiSelect',
  components: {
    FactTooltip
  }
})
export default class FactMultiSelect extends Mixins(FactMixin) {

  @Prop({default: true})
  private singleSelect?: boolean;
  @Getter('quotation/getFactValidationData') private getFactValidationData!: (productID: string, validatedFacts: string[]) => any[];

  private selectedValue: any = '';
  private opened: boolean = false;
  private search: string = '';
  private updates: any[] = [];
  private multiValues: string[] = [];

  private mounted() {
    if (!this.singleSelect) {
      this.updateMultiValues();
    } else {
      this.selectedValue = this.modelValue;
    }
  }

  private activated() {
    if (!this.singleSelect) {
      this.updateMultiValues();
    } else {
      this.selectedValue = this.modelValue;
    }
  }

  get filteredResults() {
    return this.factData.options.filter((el) => el.name.toLowerCase().includes(this.search.toLowerCase()));
  }

  private updateMultiValues() {
    this.multiValues = _.map(this.factData.options.filter((opt) => opt.currentValue), (opt) => opt.value);
  }

  private format(error) {
    return Util.stringToHtml(error);
  }

  private getUIDs() {
    if (this.singleSelect) {
      return [this.getUID()];
    } else {
      return _.map(this.factData.options, (option: any) => {
        return Util.generateUid(this.factData._productCode, option.value);
      });
    }
  }

  private multiUpdate(id: string, value: boolean) {
    this.updates.push({factID: id, factValue: `${value}`});
  }

  private getChipLabel(value) {
    if (!_.isArray(value)) {
      const selected = this.factData.options.find((el) => el.value === value);
      return _.get(selected, 'name', '');
    } else {
      const selected = _.filter(this.factData.options, (el) => value.includes(el.value));
      let text = '';
      _.forEach(selected, (s, index) => {
        text += (index > 0 ? ', ' : '') + s.name;
      });
      return text;
    }
  }

  private close() {
      // NOOP
  }

  private removeAll() {
    this.selectedValue = '';
  }
  private openModal() {
    this.search = '';
    this.opened = true;
  }

  private cancel() {
    this.opened = false;
  }

  private ok() {
    this.opened = false;
    if (this.singleSelect) {
      this.modelValue = this.selectedValue;
      this.updateModelAndValidate(this.modelValue);
    } else {
      this.updateMultiValues();
      this.updateFn(this.updates);
      this.updates = [];
    }
  }

  get validations() {
    const factIds = _.map(this.factData.options, (el) => el.value);
    return this.getFactValidationData(this.factData._productID, factIds);
  }

  @Watch('validations')
  private onValidated() {
    _.forEach(this.getUIDs(), (uid) => {
      (this as any).$resetFactErrors(uid);
    });
    const factIds = _.map(this.factData.options, (el) => el.value);
    const shiftResult = this.validations;
    _.forEach(shiftResult, (err: any) => {
      if (_.difference(err.error.relatedFacts, factIds).length === 0) {
        (this as any).$addError(this.getUIDs()[0], err.error.message);
      }
    });
  }
}
